<template>
  <div id="app" @mousewheel="wheel">
    <TopBar v-if="$store.state.isMobile === 'web'" />
    <router-view />
  </div>
</template>
<script>
import TopBar from '@/components/top-bar.vue';
export default {
  name: 'App',
  components: {
    TopBar,
  },
  data() {
    return {
      routeIndex: 0, // 鼠标滚动时候记录次数
      scrollActive: true,
    };
  },
  watch: {
    $route(to) {
      this.routeIndex = to.meta.scrollIndex; // 当时刷新页面的时候保持住在当前页的值
    },
  },
  methods: {
    delScrollToPage(num) {
      switch (num) {
        case 0:
          this.$router.push('/');
          break;
        case 1:
          this.$router.push('/technical-service');
          break;
        case 2:
          this.$router.push('/about');
          break;
        case 3:
          this.$router.push('/join-us');
          break;
        case 4:
          this.$router.push('/contact');
          break;
        default:
          break;
      }
    },
    wheel(e) {
      const changeScroll = () => {
        const sd = e.wheelDelta || -e.detail;
        if (sd > 0) {
          if (this.routeIndex == 4) return;
          this.routeIndex++;
          this.delScrollToPage(this.routeIndex);
        } else {
          if (this.routeIndex == 0) return;
          this.routeIndex--;
          this.delScrollToPage(this.routeIndex);
        }
      };
      // var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
      // isMac && this.$store.state.isMobile != 'wap' && this.immediate(changeScroll, 50);
      this.$store.state.isMobile != 'wap' && this.immediate(changeScroll, 50);
    },
    //防抖动
    immediate(func, wait) {
      if (this.scrollActive) {
        func();
      }
      this.scrollActive = false;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.scrollActive = true;
      }, wait);
    },
  },
  mounted() {
    // 如果是手机端的时候去到首页home 技术详情和加入我们详情的时候wap端还是在详情页（!this.$route.meta?.techPage）
    if (this.$store.state.isMobile === 'wap' && !this.$route.meta?.techPage) {
      this.$router.push('/');
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Verdana, Geneva, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
