import Vue from 'vue';
import VueRouter from 'vue-router';
const Home = () => import('../views/home.vue');
const TechnicalService = () => import('../views/technical-service.vue');
const About = () => import('../views/about.vue');
const JoinUs = () => import('../views/join-us.vue');
const Contact = () => import('../views/contact.vue');

//详情页
const TechDetails = () => import('../views/tech-details/index.vue');
const JoinUsDetail = () => import('../views/join-us-detail/index.vue');

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // 防止跳转 this.$router.push('/') 报警告错误
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      scrollIndex: 0,
    },
  },
  {
    path: '/technical-service',
    name: 'TechnicalService',
    component: TechnicalService,
    meta: {
      scrollIndex: 1,
    },
    // children:[
    //   {
    //     path: 'blockchain-one',
    //     name: 'BlockchainOne',
    //     component: BlockchainOne,
    //   },

    //   {
    //     path:'blockchain-consulting',
    //     component:BlockchainConsulting
    //   },
    // ]
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      scrollIndex: 2,
    },
  },
  {
    path: '/join-us',
    name: 'JoinUs',
    component: JoinUs,
    meta: {
      scrollIndex: 3,
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      scrollIndex: 4,
    },
  },

  {
    path: '/tech-details',
    name: 'TechDetails',
    component: TechDetails,
    meta: {
      techPage: 'tech',
    },
  },
  {
    path: '/join-us-detail',
    name: 'JoinUsDetail',
    component: JoinUsDetail,
    meta: {
      techPage: 'join-us',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
