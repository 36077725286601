import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/css/init.css';
import 'animate.css';
import './assets/images/svg/index.js';

import fastclick from 'fastclick';
fastclick.attach(document.body);

Vue.config.productionTip = false;

import VueParticles from 'vue-particles';
Vue.use(VueParticles);

import DrawerLayout from 'vue-drawer-layout';
Vue.use(DrawerLayout);

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
});

const flag = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
if (flag) {
  // 判断是手机端还是web端
  store.commit('setIsMobile', 'wap');
} else {
  store.commit('setIsMobile', 'web');
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
