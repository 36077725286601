var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top-bar",style:({ background: _vm.ishaveBgColor ? '#000000' : '' })},[_c('div',{staticClass:"left"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.toHome}},[_c('img',{staticClass:"hooked-logo",attrs:{"src":require('../assets/images/home/logo.png' + _vm.imgSuffixWebp),"alt":""}})]),_c('div',{staticClass:"nav-wrap"},_vm._l((_vm.navData),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.toRoute(item)},"mouseover":function($event){return _vm.onHover(item.id)},"mouseleave":function($event){return _vm.onLeave(item.id)}}},[_c('div',{class:[
            {
              'active-Path': _vm.targetIndex === item.path,
              'item-active-white': _vm.targetIndex === item.path || _vm.hoverFlag === item.id,
            },
            'name',
          ]},[_vm._v(" "+_vm._s(item.name)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(index == 1 || index == 3 ? true : false),expression:"index == 1 || index == 3 ? true : false"}],class:_vm.menuClass(item)}),_c('span',{class:[
              {
                'active-line': _vm.targetIndex === item.path || _vm.hoverFlag === item.id,
              },
            ]})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.menuChild && _vm.hoverFlag === index),expression:"item.menuChild && hoverFlag === index"}],staticClass:"nav-child"},_vm._l((item.menuChild),function(child,cIndex){return _c('div',{key:cIndex,staticClass:"list",on:{"click":function($event){$event.stopPropagation();return _vm.toDetailsPage(item.detailsPageRouter, cIndex)}}},[_c('span',[_vm._v(_vm._s(child))])])}),0)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }