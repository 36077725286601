<template>
  <div class="top-bar" :style="{ background: ishaveBgColor ? '#000000' : '' }">
    <div class="left">
      <a href="javascript:void(0)" @click="toHome">
        <img class="hooked-logo" :src="require('../assets/images/home/logo.png' + imgSuffixWebp)" alt="" />
      </a>
      <div class="nav-wrap">
        <div
          class="item"
          v-for="(item, index) in navData"
          :key="index"
          @click="toRoute(item)"
          @mouseover="onHover(item.id)"
          @mouseleave="onLeave(item.id)"
        >
          <div
            :class="[
              {
                'active-Path': targetIndex === item.path,
                'item-active-white': targetIndex === item.path || hoverFlag === item.id,
              },
              'name',
            ]"
          >
            {{ item.name }}
            <span :class="menuClass(item)" v-show="index == 1 || index == 3 ? true : false"></span>
            <span
              :class="[
                {
                  'active-line': targetIndex === item.path || hoverFlag === item.id,
                },
              ]"
            ></span>
          </div>
          <div class="nav-child" v-show="item.menuChild && hoverFlag === index">
            <div
              class="list"
              @click.stop="toDetailsPage(item.detailsPageRouter, cIndex)"
              v-for="(child, cIndex) in item.menuChild"
              :key="cIndex"
            >
              <span>{{ child }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { imgSuffix } from '../utils/common';
export default {
  name: '',
  data() {
    return {
      imgSuffixWebp: imgSuffix,
      ishaveBgColor: false,
      hoverFlag: '',
      navData: [
        {
          id: 0,
          name: 'HOME',
          path: '/',
        },
        {
          id: 1,
          name: 'TECHNICAL & SERVICE',
          path: '/technical-service',
          menuChild: [
            'Blockchain consulting',
            'Blockchain architecture design',
            'Smart contract development',
            'Blockchain application development',
            'Digital asset management',
            'Maintenance and support',
          ],
          detailsPageRouter: '/tech-details',
        },
        {
          id: 2,
          name: 'ABOUT',
          path: '/about',
        },
        {
          id: 3,
          name: 'JOIN US',
          path: '/join-us',
          menuChild: [
            'Senior Contract Development Engineer ',
            'Blockchain Product Manager',
            'Original Art Designer',
            'Marketing Operations Specialist',
          ],
          detailsPageRouter: '/join-us-detail',
        },
        {
          id: 4,
          name: 'CONTACT',
          path: '/contact',
        },
      ],
      targetIndex: '/',
      scrollPath: '',
    };
  },
  watch: {
    $route(to) {
      this.scrollPath = to.fullPath; // 记录滚动的鼠标滚动时候详情页不让其滚动
      // 路由跳转时候
      switch (to?.meta.techPage) {
        case 'tech':
          this.ishaveBgColor = true;
          this.targetIndex = '/technical-service';
          break;
        case 'join-us':
          this.ishaveBgColor = true;
          this.targetIndex = '/join-us';
          break;
        default:
          this.ishaveBgColor = false;
          this.targetIndex = to.fullPath;
          break;
      }
    },
  },
  methods: {
    menuClass(item) {
      if (item.path === this.targetIndex || this.hoverFlag === item.id) {
        return 'menu-icon-bottom';
      } else {
        return 'menu-icon-top';
      }
    },
    toHome() {
      this.$router.push('/');
    },
    onHover(num) {
      this.hoverFlag = num;
    },
    onLeave() {
      this.hoverFlag = '';
    },
    toRoute(item) {
      if (this.$route.name === 'TechDetails' && item.id == 1) return; // 如果是技术详情页的时候不让点击跳转
      if (this.$route.name === 'JoinUsDetail' && item.id == 3) return; // 如果是在加入我们详情页的时候不让点击跳转
      this.$router.push(item.path);
    },
    toDetailsPage(path, id) {
      // 去到详情页
      if (path === '/tech-details') {
        this.$store.commit('setTechTabIndex', id);
      } else {
        this.$store.commit('setJoinIndex', id);
      }
      this.$router.push(path);
    },
    delScrollToPage(num) {
      switch (num) {
        case 0:
          this.$router.push('/');
          break;
        case 1:
          this.$router.push('/technical-service');
          break;
        case 2:
          this.$router.push('/about');
          break;
        case 3:
          this.$router.push('/join-us');
          break;
        case 4:
          this.$router.push('/contact');
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    // window.addEventListener('mousewheel', this.handleScroll);
    // 页面刷新时
    switch (this.$route?.meta.techPage) {
      case 'tech':
        this.ishaveBgColor = true;
        this.targetIndex = '/technical-service';
        break;
      case 'join-us':
        this.ishaveBgColor = true;
        this.targetIndex = '/join-us';

        break;
      default:
        this.ishaveBgColor = false;
        this.targetIndex = this.$route.fullPath;
        break;
    }
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  position: fixed;
  height: 90px;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .wild-cash-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .left {
    display: flex;
    align-items: center;

    a {
      font-size: 0;
    }

    .hooked-logo {
      margin-left: 60px;
      width: 175px;
      height: auto;
      transition: all 0.5s ease;
    }

    .hooked-logo:hover {
      transform: translateY(-0.26042vw);
    }

    .nav-wrap {
      margin-left: 70px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: Verdana, Geneva, sans-serif;

      .item {
        position: relative;
        height: 90px;
        padding: 0 40px;
        white-space: nowrap;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .menu-icon-top {
          display: block;
          margin-left: 5px;
          content: '';
          width: 0;
          height: 0;
          border-bottom: 5px solid #fff;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
        }

        .menu-icon-bottom {
          display: block;
          margin-left: 5px;
          content: '';
          width: 0;
          height: 0;
          border-top: 5px solid #3084ea;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
        }

        .name {
          position: relative;
          color: #ffffff;
          font-size: 16px;
          font-family: Verdana, Geneva, sans-serif;
          display: flex;
          align-items: center;
          letter-spacing: 1px;
          font-weight: 600;

          .active-line {
            position: absolute;
            bottom: -36px;
            left: 50%;
            transform: translateX(-50%);
            display: block;
            width: 60px;
            height: 3px;
            background: linear-gradient(90deg, #28e4fa, #1764cd);
            border-radius: 0.02rem;
          }
        }

        .name:hover {
          background: linear-gradient(90deg, #28e4fa 0.68359375%, #1764cd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
        }

        .active-Path {
          background: linear-gradient(90deg, #28e4fa 0.68359375%, #1764cd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
        }
      }

      .nav-child {
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 99999;
        background: #0b1225;
        border: 1px solid rgba(43, 66, 103, 1);
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 1);
        box-sizing: border-box;

        .list {
          position: relative;
          height: 50px;
          line-height: 50px;
          font-family: Verdana, Geneva, sans-serif;
          font-size: 14px;
          letter-spacing: 0;
          color: #fff;
          font-weight: 400;
          padding-right: 10px;
          opacity: 0.7;
          padding-left: 24px;
          box-sizing: border-box;
          border-bottom: 1px solid #272538;

          .active-child span {
            display: block;
            font-size: 14px;
            background: linear-gradient(90deg, #28e4fa 0.68359375%, #1764cd 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: transparent;
          }
        }

        .list::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 13px;
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          background: #fff;
          border-radius: 50%;
        }

        .list:hover::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 13px;
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          background: linear-gradient(90deg, #28e4fa 0.68359375%, #1764cd 100%);
          border-radius: 50%;
        }

        .list:hover span {
          background: linear-gradient(90deg, #28e4fa 0.68359375%, #1764cd 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
        }
      }

      .item-active-white:before {
        position: absolute;
        left: -10px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-right: 4px solid #348add;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
      }

      .item-active-white:after {
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        content: '';
        width: 0;
        height: 0;
        border-left: 4px solid #348add;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
      }
    }
  }
}
</style>
