import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: 'web', // 默认是web端
    techIndex: sessionStorage.getItem('cacheId') || '', // 技术详情页切换导航的状态
    joinusIndex: sessionStorage.getItem('cacheIndex') || 0,
  },
  mutations: {
    setTechTabIndex(state, val) {
      sessionStorage.setItem('cacheId', val);
      state.techIndex = val;
    },
    setJoinIndex(state, val) {
      sessionStorage.setItem('cacheIndex', val);
      state.joinusIndex = val;
    },
    setIsMobile(state, val) {
      state.isMobile = val;
    },
  },
});
