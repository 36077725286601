export const throttle = (fn, time) => {
  // 节流
  let flag = true;
  return function () {
    if (!flag) return;
    flag = false;
    setTimeout(() => {
      fn.apply(this, arguments);
      flag = true;
    }, time);
  };
};

export const debounce = (fn, ms = 300) => {
  let timeout;
  let args = [];
  return function (arg) {
    args.push(arg);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn(arg, args);
      args = [];
    }, ms);
  };
};

const BrowserType = () => {
  const { userAgent } = navigator;

  if (/^(?=.Safari)(?!.Chrome)/.test(userAgent)) {
    return 'Safari';
  }

  if (userAgent.indexOf('Edge') > -1) {
    return 'Edge';
  }

  if (userAgent.indexOf('Firefox') > -1) {
    return 'Firefox';
  }

  if (userAgent.indexOf('Chrome') > -1) {
    return 'Chrome';
  }

  if (userAgent.indexOf('.NET') > -1) {
    return 'IE';
  }
};

const checkWebp = () => {
  try {
    if (BrowserType() === 'Safari') {
      return false;
    } else {
      return document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
  } catch (err) {
    return false;
  }
};

// 是否支持webp格式
export const supportWebp = checkWebp();
export const imgSuffix = supportWebp ? '.webp' : '';
